/** @jsx jsx */
import { jsx, Box, Container, Flex, Styled, Text } from "theme-ui"
import { graphql } from "gatsby"
import Img from "gatsby-image/withIEPolyfill"
import BackgroundImage from "gatsby-background-image"
import { HelmetDatoCms } from "gatsby-source-datocms"

import {
  Hero,
  Overlay,
} from "@giraldomac/gatsby-theme-wvumedicine/src/components/elements"
import {
  Layout,
  Section,
  Main,
} from "@giraldomac/gatsby-theme-wvumedicine/src/components/layout"

import {
  ColorBar,
  DonorStoryGrid,
  SectionHeading,
} from "../components/elements"

const DonorStoryPage = ({ data }) => {
  const page = data.datoCmsDonorStory

  return (
    <Layout>
      <HelmetDatoCms seo={page.seoMetaTags} />
      <Main>
        <Hero>
          <BackgroundImage Tag="div" fluid={data.datoCmsAsset.fluid}>
            <Container
              sx={{ position: "relative", px: "1em", py: [5, 6], zIndex: 10 }}
            ></Container>
            <Overlay sx={{ opacity: 1 }} />
          </BackgroundImage>
          <ColorBar sx={{ position: "absolute", bottom: 0, left: 0 }} />
        </Hero>

        <Box sx={{ mt: [-5, -6] }}>
          <Container sx={{ px: "1em" }}>
            <Flex
              sx={{
                width: ["full"],
                flexWrap: "wrap",
                justifyContent: "center",
              }}
            >
              <Box sx={{ width: ["full"] }}>
                <Img
                  fluid={page.image.fluid}
                  objectFit="cover"
                  objectPosition="50% 50%"
                  sx={{
                    borderColor: "white",
                    borderStyle: "solid",
                    borderWidth: [4, 5, 6],
                    borderRadius: "full",
                    boxShadow: "lg",
                    mx: "auto",
                    width: ["140px", "180px", "220px"],
                    height: ["140px", "180px", "220px"],
                  }}
                />
              </Box>
              <Box sx={{ textAlign: "center", width: ["full", "4/5", "2/3"] }}>
                <Styled.h3 as="h1">{page.title}</Styled.h3>
              </Box>
            </Flex>
          </Container>
        </Box>

        {/* Story */}
        <Box id="story" as="article" py={4}>
          <Container sx={{ px: "1em" }}>
            <Flex
              sx={{
                width: ["full"],
                flexWrap: "wrap",
                justifyContent: "center",
              }}
            >
              <Box sx={{ width: ["full", "4/5", "2/3"] }}>
                <Text
                  sx={{ width: ["full"] }}
                  dangerouslySetInnerHTML={{
                    __html: page.contentNode.childMarkdownRemark.html,
                  }}
                />
              </Box>
            </Flex>
          </Container>
        </Box>

        {/* Stories Grid */}
        <Section id="patient-stories" sx={{ bg: "warmgray" }}>
          <Container sx={{ px: "1em" }}>
            <SectionHeading sx={{ textAlign: "center" }}>
              More Stories
            </SectionHeading>
            <DonorStoryGrid />
          </Container>
        </Section>
      </Main>
    </Layout>
  )
}

export default DonorStoryPage

export const query = graphql`
  query donorStoryPageQuery($slug: String!) {
    datoCmsDonorStory(slug: { eq: $slug }) {
      contentNode {
        childMarkdownRemark {
          html
        }
      }
      id
      title
      slug
      image {
        fluid(maxWidth: 400, imgixParams: { fm: "jpg", auto: "compress" }) {
          ...GatsbyDatoCmsFluid
        }
      }
      meta {
        status
      }
      seoMetaTags {
        tags
      }
    }
    datoCmsAsset(id: { eq: "DatoCmsAsset-1741425" }) {
      id
      fluid(maxWidth: 1600, imgixParams: { fm: "jpg", auto: "compress" }) {
        ...GatsbyDatoCmsFluid
      }
    }
  }
`
